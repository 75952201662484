@import 'mixin';
@import 'theme';

// Utility classes
.px-16 {
	padding-left: 16px;
	padding-right: 16px;
}

.px-desk {
	@include laptop-up {
		max-width: 1184px;
		margin: 0 auto;
	}
}

.reverse {
	transform: scaleX(-1);
}

.hr {
	background-color: var(--color-gray-light);
	height: 1px;
	border: none;
}

.mobile {
	@include mobile-up {
		display: block;
	}

	@include laptop-up {
		display: none;
	}
}

.desktop {
	@include mobile-up {
		display: none;
	}

	@include laptop-up {
		display: block;
	}
}

.btn {
	width: 100%;
	height: 48px;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	border-radius: 4px;

	&--primary {
		color: var(--color-white);
		background: var(--color-pink);
	}

	&--ghost {
		color: var(--color-pink);
	}

	&--inverted {
		color: var(--color-pink);
		border: 1px solid var(--color-pink);
	}

	&--disabled {
		opacity: 0.5;
	}

	&--loading-wrapper {
		position: relative;
	}
	&--loading {
		position: absolute;
		top: 5px;
		right: 10px;
	}

	&--small {
		height: 30px;
		font-size: 12px;
	}
}

/* SWITCH */
.switch {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__txt {
		color: var(--color-gray-dark);
		width: calc(100% - 60px);
		font-size: 14px;
		line-height: 19px;
	}

	&__input {
		height: 0;
		opacity: 0;
		width: 0;
	}

	&__round {
		// background-color: #c7c7c7;
		background-color: var(--color-gray-disabled);
		border-radius: 13.5px;
		cursor: pointer;
		height: 28px;
		transform: translate(0px);
		transition: 0.4s;
		width: 59px;
		display: flex;

		&::before {
			content: '';
			background: var(--color-white);
			border: 1px solid var(--color-white);
			border-radius: 50%;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
			height: 32px;
			transition: transform 0.4s;
			transform: translate(0, -2px);
			width: 32px;
			display: flex;
		}
	}

	.switch__input:checked + .switch__round {
		background-color: var(--color-pink);

		&::before {
			transform: translate(28px, -2px);
		}
	}

	.switch__input:focus + .switch__round {
		// box-shadow: 0 0 1px gray;
		box-shadow: 0 0 1px var(--color-gray-disabled);
	}
}
