@import 'swiper/swiper-bundle.min.css';
@import 'theme';
@import 'mixin';
@import 'reset';
@import 'utility';

body {
	color: var(--color-black);
	font-family: 'Latam Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-weight: normal;
	font-style: normal;
	background-color: var(--color-gray-soft);
}
.cookie-accept-all-btn.cookie-btn-filled {
	text-align: center;
}
